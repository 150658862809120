import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Metadata from "../components/Metadata/Metadata"
import LayoutWork from "../layout/LayoutWork"
import Heading from "../components/Heading/Heading"
import Filter from "../components/Filter/FilterWork"
import styles from "../components/Gallery/Gallery.module.scss"
import {
    pagePathBuilder,
    frontmatterPathBuilder,
} from "../helpers/pathBuilders"

export const pageQuery = graphql`
    query($tag: String) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                fields: { sourceInstanceName: { eq: "work-single" } }
                frontmatter: { tags: { in: [$tag] } }
            }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        pathDirectory
                        pathName
                        title
                        description
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext
    const { edges } = data.allMarkdownRemark
    const Main = () => (
        <ul className={styles.gallery}>
            {edges.map(({ node }) => {
                return (
                    <li
                        key={frontmatterPathBuilder(node).title}
                        className={styles.gallery__item}
                    >
                        <Link
                            to={pagePathBuilder(node)}
                            className={styles.gallery__link}
                        >
                            <div className={styles.gallery__text_wrapper}>
                                <span className={styles.gallery__item_title}>
                                    {frontmatterPathBuilder(node).title}
                                </span>
                                <span
                                    className={styles.gallery__item_description}
                                >
                                    {frontmatterPathBuilder(node).description}
                                </span>
                            </div>
                            {frontmatterPathBuilder(node).thumbnail && (
                                <Img
                                    fluid={
                                        frontmatterPathBuilder(node).thumbnail
                                            .childImageSharp.fluid
                                    }
                                    className={styles.gallery__item_image}
                                />
                            )}
                        </Link>
                    </li>
                )
            })}
        </ul>
    )

    return (
        <>
            <Metadata title={`Work Filtered by "${tag}"`} />
            <LayoutWork
                heading={
                    <Heading
                        headingLevel="1"
                        headingTitle={`Work, Filtered by ${tag}`}
                    />
                }
                filter={<Filter resetFilter={true} />}
                gallery={<Main />}
            />
        </>
    )
}

export default Tags
